<template>
  <div class="bg-b-lue">
    <div class="frame-35628">
      <div class="title-wrapper">
        <div class="title">{{ newBank ? "Create Entity" : "Edit Entity" }}</div>
        <i class="fa-solid fa-xmark fa-xl" @click="close()"></i>
      </div>
      <form @submit.prevent="submitForm" class="bank-details-wrapper">
        <div class="bank-details">
          <div class="field-wrapper">
            <div class="field-title">Entity Name</div>
            <input
              class="input"
              type="text"
              v-model="form.bankName"
              @input="checkForChanges"
              placeholder="Enter Entity Name"
              required
            />
          </div>
          <div class="field-wrapper">
            <div class="field-title">Country</div>
            <select
              v-model="form.country"
              @change="checkForChanges"
              class="input"
              :style="{ height: mobile ? '56px' : '42px' }"
              required
            >
              <option value="" disabled>Select a country</option>
              <option
                v-for="country in countries"
                :value="country.id"
                :key="country.id"
              >
                {{ country.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="bank-details">
          <div class="field-wrapper">
            <div class="field-title">Website</div>
            <input
              class="input"
              type="text"
              v-model="form.website"
              @input="checkForChanges"
              placeholder="Entity Website URL"
            />
          </div>
          <div class="field-wrapper">
            <div class="field-title">Relationship Manager</div>
            <select
              v-model="form.rm"
              @change="checkForChanges"
              class="input"
              :style="{ height: mobile ? '56px' : '42px' }"
              required
            >
              <option value="" disabled>Select Relationship Manager</option>
              <option v-for="rm in rms" :value="rm.id" :key="rm.id">
                {{ rm.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="bank-details">
          <div class="field-wrapper">
            <div class="field-title">Reports Validity (in days)</div>
            <select
              v-model="form.reportValidity"
              @input="checkForChanges"
              class="input"
              :style="{ height: mobile ? '56px' : '42px' }"
              required
            >
              <option value="" disabled>Validity Period</option>
              <option
                v-for="noOfdays in productConfig"
                :value="noOfdays"
                :key="noOfdays"
              >
                {{ noOfdays }}
              </option>
            </select>
          </div>
          <div class="field-wrapper">
            <div class="field-title">Entity Type</div>
            <select
              v-model="form.entityType"
              @change="checkForChanges"
              class="input"
              :style="{ height: mobile ? '56px' : '42px' }"
              required
            >
              <option value="" disabled>Select Relationship Manager</option>
              <option v-for="entity in entityTypeList" :value="entity.name" :key="entity.id">
                {{ entity.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="buttons">
          <button
            type="submit"
            class="save-button"
            :style="{
              opacity: loading || !isChanged ? '0.5' : '1',
              cursor: isChanged ? 'pointer' : 'not-allowed',
            }"
            :disabled="loading || !isChanged"
          >
            {{ newBank ? "Create Entity" : "Save Changes" }}
          </button>
          <button class="cancel-button" @click="close()">Cancel</button>
        </div>
      </form>
    </div>
  </div>
  <Toastify ref="toastContainer" />
</template>
<script>
import Toastify from "@/components/Toastify.vue";
import {
  GetProductConfig,
  AllCountries,
  GetRMList,
  UpdateBankDetails,
  AddBank,
} from "@/api/APIs";

export default {
  name: "EditBank",
  components: {
    Toastify,
  },
  props: {
    bankDetails: {
      type: Object,
      required: true,
    },
    newBank: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      form: {
        bankName: "",
        country: "",
        website: "",
        rm: "",
        reportValidity: "",
        entityType: "",
      },
      entityTypeList: [
        {
          id: 1,
          name: "BANK",
        },
        {
          id: 2,
          name: "CORPORATE",
        },
      ],
      originalForm: {},
      countries: [],
      rms: null,
      loading: false,
      isChanged: false,
      productConfig: [],
      mobile: window.innerWidth > 767,
    };
  },
  async mounted() {
    await this.ConfigItems();
    await this.fetchCountryList();
    await this.RMList();
    this.prefillForm();
  },
  methods: {
    async fetchCountryList() {
      try {
        const response = await AllCountries(true);
        this.countries = await response.allCountries;
      } catch (error) {
        this.loginError = "Error occurred during login.";
        console.error(error);
      }
    },
    async ConfigItems() {
      try {
        const response = await GetProductConfig();
        if (response) {
          this.productConfig = response.entityProductConfig?.reportValidityDays;
        } else {
          this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
    },
    async RMList() {
      try {
        const response = await GetRMList();
        if (response) {
          this.rms = response?.allRMsResults;
        } else {
          this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
    },
    prefillForm() {
      if (!this.newBank) {
        this.form.bankName = this.bankDetails.name || "";
        this.form.country = this.bankDetails.countryId || "";
        this.form.website = this.bankDetails.website || "";
        this.form.reportValidity = this.bankDetails.reportValidityDays || "";
        this.form.entityType = this.bankDetails.type || "";
        const selectedRM = this.rms?.find(
          (rm) => rm?.name === this.bankDetails.rmName
        );
        this.form.rm = selectedRM ? selectedRM.id : "";
        this.originalForm = { ...this.form };
      }
    },
    checkForChanges() {
      this.isChanged =
        JSON.stringify(this.form) !== JSON.stringify(this.originalForm);
    },
    async submitForm() {
      if (!this.isChanged) return;

      this.loading = true;
      if (this.newBank) {
        const payload = {
          name: this.form.bankName,
          headquarters: "",
          countryId: this.form.country,
          website: this.form.website,
          rmId: this.form.rm,
          reportValidityDays: this.form.reportValidity,
          type: this.form.entityType,
        };
        try {
          const response = await AddBank(payload);
          if (response.message == "SUCCESS") {
            this.$refs.toastContainer.addToast("Entity created successfully.", {
              type: "success",
            });
            this.close();
          } else {
            this.$refs.toastContainer.addToast(response.message, {
              type: "error",
            });
          }
        } catch (error) {
          if (error.response && error.response.status !== 403) {
            this.$refs.toastContainer.addToast(
              "Failed to create entity. Please try again.",
              { type: "error" }
            );
          }
        }
      } else {
        const payload = {
          entityId: this.bankDetails.id,
          name: this.form.bankName,
          headquarters: "",
          countryId: this.form.country,
          website: this.form.website,
          rmId: this.form.rm,
          reportValidityDays: this.form.reportValidity,
          type: this.form.entityType,
        };
        try {
          const response = await UpdateBankDetails(payload);
          if (response.message == "Entity updated successfully") {
            this.$refs.toastContainer.addToast(
              "Entity details updated successfully.",
              { type: "success" }
            );
            this.close();
          } else {
            this.$refs.toastContainer.addToast(response.message, {
              type: "error",
            });
          }
        } catch (error) {
          if (error.response && error.response.status !== 403) {
            this.$refs.toastContainer.addToast(
              "Failed to update entity details. Please try again.",
              { type: "error" }
            );
          }
        }
      }
      this.loading = false;
    },
    close() {
      this.form = {
        bankName: "",
        country: "",
        website: "",
        rm: "",
        reportValidity: "",
        entityType: "",
      };
      this.originalForm = {};
      this.isChanged = false;

      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.bg-b-lue {
  background: rgba(0, 0, 0, 8%);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  z-index: 9999;
}
.frame-35628 {
  display: flex;
  width: 70%;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 56px 56px 0px rgba(17, 54, 90, 0.1);
  position: relative;
  box-shadow: 0px 24px 24px 0px rgba(17, 54, 90, 0.2);
}
.title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.title {
  color: #11365a;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.24px;
}
.bank-details-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}
.bank-details {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}
.field-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
}
.field-title {
  color: #11365a;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.2px;
}
.input {
  /* display: flex; */
  padding: 12px 24px;
  /* align-items: center; */
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid rgba(17, 54, 90, 0.4);
  background: #fff;
  box-shadow: none;
  color: #11365a;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.16px;
  outline: none;
}
.buttons {
  display: flex;
  align-items: flex-start;
  gap: 19px;
  width: calc(50% - 8px);
  width: 100%;
  box-shadow: none;
  padding: 0;
  width: 100%;
  padding: 0;
  box-shadow: none;
}
.save-button {
  display: flex;
  width: 50%;
  align-self: stretch;
  padding: 12px 26px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  background: var(--Dark-Blue, #11365a);
  color: #fff;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.16px;
  height: 52px;
  outline: none;
}
.cancel-button {
  display: flex;
  width: 50%;
  align-self: stretch;
  padding: 12px 26px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  background: white;
  border: 2px solid #11365a;
  color: #11365a;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.16px;
  height: 52px;
  outline: none;
}
.save-button:hover,
.cancel-button:hover {
  box-shadow: 0px 0px 20px 4px #cbe5ff;
}
@media only screen and (max-width: 767px) {
  .frame-35628 {
    width: 90%;
    padding: 1rem;
  }
  .bank-details {
    flex-direction: column;
  }
  .field-wrapper {
    align-self: stretch;
  }
  .input {
    padding: 5px 24px;
    margin: 0;
  }
  .buttons {
    width: 100%;
    box-shadow: none;
    padding: 0;
  }
  .bank-details-wrapper,
  .bank-details {
    gap: 15px;
  }
  .title {
    font-size: 20px;
  }
  .field-title {
    font-size: 16px;
  }
  .save-button,
  .cancel-button {
    height: 42px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) {
}
@media only screen and (min-width: 1156px) {
}
</style>
