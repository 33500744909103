const referralCredits = "$30";
const blogCategories = ["Due Diligence Reports", "Risks", "Cross Border Trade", "Emerging Markets", "Currency and Settlement"];
const prodCDN = 'https://static.credencedata.com/images/';
const stagingCDN = 'https://static.credencedata.com/images_stg/';
const reportData = [
  {
    id: 1,
    name: "Oriflame Cosmetics Global S.A, Schaffhausen Branch",
    country: "Luxembourg",
    type: "FRESH",
    reportFileKey:
      "samplereports/ORIFLAME-COSMETICS-GLOBAL-S.A-CredenceSampleReport.pdf",
  },
  {
    id: 2,
    name: "ITOCHU SINGAPORE PTE LTD",
    country: "Singapore",
    type: "FRESH",
    reportFileKey:
      "samplereports/ITOCHU-SINGAPORE-PTE-LTD-CredenceSampleReport.pdf",
  },
  {
    id: 3,
    name: "Analytik Jena GmbH+Co. KG",
    country: "Germany",
    type: "FRESH",
    reportFileKey:
      "samplereports/AnalytikJenaGmbH-Co.KG-Germany-CredenceSampleReport.pdf",
  },
  {
    id: 4,
    name: "BIOFER S.P.A.",
    country: "Italy",
    type: "FRESH",
    reportFileKey:
      "samplereports/BIOFER-S.P.A.-Italy-CredenceSampleReport.pdf",
  },
  {
    id: 5,
    name: "Redington Limited",
    country: "India",
    type: "FRESH",
    reportFileKey:
      "samplereports/RedingtonLimited-India-CredenceSampleReport.pdf",
  },
];

export default { reportData, referralCredits, blogCategories, prodCDN, stagingCDN };
