import { createWebHistory, createRouter } from "vue-router";
import VueCookies from "vue-cookies";
import ReplenishCredits from "@/views/ReplenishCredits.vue";
import NotFound from "@/views/NotFound.vue";
import HomePage from "@/components/HomePage/HomePage/HomePage.vue";
// import CorporatesPage from "@/components/HomePage/CorporatesPage/CorporatesPage.vue";
import MyOrders from "@/views/MyOrders.vue";
import BankUserOrders from "@/views/BankUserOrders.vue";
// import MyRepository from "@/views/MyRepository.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import ContactUs from "@/views/Contact.vue";
import LoginScreen from "@/views/Login.vue";
import AboutUs from "@/views/AboutUs.vue";
import OrderConfirmationModal from "@/views/Modals/OrderConfirmation.vue";
import PaymentRetryModal from "@/views/Modals/PaymentRetryModal.vue";
// import UserDashboard from "@/views/RegisterdUser/Dashboard/Dashboard.vue";
import BankUserDashboard from "@/views/RegisterdUser/Dashboards/BankUserDashboard.vue";
import Products from "@/views/Products.vue";
import BlogsBanner from "@/views/BlogsBanner.vue";
import SearchPage from "@/views/SearchPage.vue";
import PaymentRetry from "@/views/PaymentRetry.vue";
import UserProfile from "@/views/RegisterdUser/UserProfile.vue";
import TeamInvite from "@/views/TeamInvite.vue";
import BanksAndHQ from "@/views/InternalUsersScreens/BanksAndHQ.vue";
import SampleReports from "@/views/SampleReports.vue";
import Rewards from "@/views/Rewards.vue";
import WhatsappTermsAndConditions from "@/views/WhatsappIntegration/TermsAndConditions.vue";
import AllOrders from "@/views/Orders.vue";
import BlogEditor from "@/components/Blogs/BlogsEditor.vue";
import Blog from "@/components/Blogs/Blog.vue";

const routes = [
  {
    path: "/",
    name: "new-home",
    component: HomePage,
    beforeEnter: (to, from, next) => {
      const token = VueCookies.get("token");
      if (token) {
        next({ name: "SearchForCompanies" });
      } else {
        next();
      }
    },
  },
  {
    path: "/bankuserdashboard",
    name: "dashboard",
    component: BankUserDashboard,
    beforeEnter: (to, from, next) => {
      const userType = VueCookies.get("user-type");
      const token = VueCookies.get("token");
      if (token) {
        if (
          userType === "RETAIL" ||
          userType === "EXECUTIVE" ||
          userType === "RM"
        ) {
          next({ name: "SearchForCompanies" });
        } else {
          next();
        }
      } else {
        next({ name: "new-home" });
      }
    },
  },
  {
    path: "/blogs/:category?",
    name: "blogs-list",
    component: BlogsBanner,
    props: (route) => ({ category: route.params.category || null }),
  },
  {
    path: "/blog/:blogTitle/:blogId",
    name: "blog",
    component: Blog,
    props: true,
  },
  {
    path: "/blog-editor",
    name: "blog-editor",
    component: BlogEditor,
    beforeEnter: (to, from, next) => {
      const token = VueCookies.get("token");
      const user = VueCookies.get("user-type");
      if (token && user === "BLOG-ADMIN") {
        next();
      } else if (!token) {
        next({ name: "new-home" });
      } else {
        next({ name: "SearchForCompanies" });
      }
    },
  },
  {
    path: "/sample-reports",
    name: "ExploreOurSampleReports",
    component: SampleReports,
    beforeEnter: (to, from, next) => {
      const token = VueCookies.get("token");
      if (!token) {
        next({ name: "new-home" });
      } else {
        next();
      }
    },
  },
  {
    path: "/rewards",
    name: "CredenceConnectReferralProgram",
    component: Rewards,
    beforeEnter: (to, from, next) => {
      const token = VueCookies.get("token");
      if (!token) {
        next({ name: "new-home" });
      } else {
        next();
      }
    },
  },
  {
    path: "/search/:country?/:company?",
    name: "SearchForCompanies",
    props: true,
    component: SearchPage,
  },
  {
    path: "/payment/success",
    name: "OrderConfirmationModal",
    props: true,
    component: OrderConfirmationModal,
  },
  {
    path: "/payment/no-success",
    name: "PaymentRetryModal",
    props: true,
    component: PaymentRetryModal,
  },
  {
    path: "/payment/retry",
    name: "PaymentRetry",
    props: true,
    component: PaymentRetry,
  },
  {
    path: "/contact",
    name: "ShareYourThoughts",
    component: ContactUs,
  },
  {
    path: "/product",
    name: "ProductsPage",
    component: Products,
  },
  {
    path: "/aboutus",
    name: "AboutUs",
    component: AboutUs,
  },
  {
    path: "/profile",
    name: "YourProfile",
    props: true,
    component: UserProfile,
    beforeEnter: (to, from, next) => {
      const token = VueCookies.get("token");
      if (!token) {
        next({ name: "new-home" });
      } else {
        next();
      }
    },
  },
  {
    path: "/banks-&-hq",
    name: "RM-Admin-portal",
    props: true,
    component: BanksAndHQ,
    beforeEnter: (to, from, next) => {
      const token = VueCookies.get("token");
      const user = VueCookies.get("user-type");
      if (token && user === "RM") {
        next();
      } else if (!token) {
        next({ name: "new-home" });
      } else {
        next({ name: "SearchForCompanies" });
      }
    },
  },
  {
    path: "/MyOrders",
    name: "Orders",
    props: true,
    component: ["HEAD", "MANAGER", "EXECUTIVE"].includes(
      VueCookies.get("user-type")
    )
      ? BankUserOrders
      : MyOrders,
    beforeEnter: (to, from, next) => {
      const token = VueCookies.get("token");
      if (!token) {
        next({ name: "new-home" });
      } else {
        next();
      }
    },
  },
  {
    path: "/MyOrders/:status",
    name: "AllOrders",
    props: true,
    component: AllOrders,
    beforeEnter: (to, from, next) => {
      const token = VueCookies.get("token");
      if (!token) {
        next({ name: "new-home" });
      } else {
        to.name = to.params.status;
        next();
      }
    },
  },
  {
    path: "/privacypolicy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/whatsapp/termsandconditions",
    name: "termsandconditions",
    component: WhatsappTermsAndConditions,
  },
  {
    path: "/team-invite",
    name: "TeamInvite",
    props: true,
    component: TeamInvite,
  },
  // {
  //   path: "/MyRepository",
  //   name: "my-repository",
  //   component: MyRepository,
  //   beforeEnter: (to, from, next) => {
  //     const token = VueCookies.get("token");
  //     if (!token) {
  //       next({ name: "new-home" });
  //     } else {
  //       next();
  //     }
  //   },
  // },
  // {
  //   path: "/Corporates",
  //   name: "Corporates",
  //   component: CorporatesPage,
  // },
  {
    path: "/replenish-credits/rm/:rm_id/user/:user_id/info/:info",
    name: "replenish-credits",
    component: ReplenishCredits,
    props: true,
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound,
  },
];

routes.push({
  path: "/login",
  name: "Login",
  props: true,
  component: LoginScreen,
});

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = VueCookies.get("token");
  if (to.name === "Login" && token) {
    next({ name: "new-home" });
  } else {
    next();
  }
});

export default router;
